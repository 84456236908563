<template>
  <b-card no-body class="rounded-0">
    <b-overlay id="overlay-background" :show="show" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-input v-model="searchTerm" class="rounded-0" placeholder="Arama..." />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end">
            <b-button-group>
              <b-button variant="primary" squared @click="openModal = true"> Yeni Kullanıcı Ekle </b-button>
              <b-button variant="info" squared :to="{ name: 'yetkiler' }"> Role İşlemleri </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col cols="12">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              styleClass="vgt-table striped bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'profile'" class="text-nowrap">
                  <b-avatar :src="avatarShow(props.row.profile)" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.ad_soyad }}</span>
                </span>
                <span v-else-if="props.column.field === 'kayit_tarihi'">
                  {{ props.row.save_date | momentFull }}
                </span>
                <span v-else-if="props.column.field === 'yetki'">
                  {{ props.row.yetki[0] }}
                </span>
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item @click="handlerUpdate(props.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Düzenle</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handlerRemove(props.row.k_no)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Sil</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <v-select
                      v-model="pageLength"
                      :options="perPageOptions"
                      :clearable="false"
                      class="invoice-filter-select"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <kullanici-ekle v-model="openModal" :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </b-card>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import KullaniciEkle from './component/kullanici_ekle.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    KullaniciEkle,
    VueGoodTable,
    vSelect,
  },
  computed: {
    avatarShow() {
      return (val) => {
        if (val != null) {
          const base_url =
            process.env.NODE_ENV === 'development'
              ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
              : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
          return `${base_url}uploads/profile/${val}`;
        }
      };
    },
  },
  data() {
    return {
      show: false,
      closeModal: false,
      updateData: {},
      master: 'user',
      openModal: false,
      pageLength: 10,
      perPageOptions: [10, 25, 50, 100],
      columns: [
        {
          label: 'Profil',
          field: 'profile',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Telefon',
          field: 'telefon',
        },
        {
          label: 'Kayıt Tarihi',
          field: 'kayit_tarihi',
        },
        {
          label: 'Yetki',
          field: 'yetki',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    };
  },
  created() {
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      await this.$store.dispatch('kullaniciListele');
      this.rows = await this.$store.getters.getKullanicilar;
      this.show = false;
    },
    handlerUpdate(task) {
      if (task) {
        this.updateData = task;
        this.openModal = true;
      }
    },
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('kullaniciSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Ekleme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
  },
  watch: {
    closeModal(val) {
      if (val == false) {
        this.openModal = false;
        this.updateData = {};
        this.handlerGetData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
