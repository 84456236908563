<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('closeModal', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <b-overlay :show="show" rounded="sm" no-fade>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
            <h5 class="mb-0">Kullanıcı Ekle</h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form
              @submit.stop.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
              class="p-2"
              autocomplete="off"
              enctype="multipart/form-data"
            >
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Ad Soyad">
                      <b-form-input
                        class="rounded-0"
                        ref="ad_soyad"
                        v-model="form.ad_soyad"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Kullanıcı Adı ( Email )" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Kullanıcı Adı ( Email )">
                      <b-form-input
                        class="rounded-0"
                        ref="email"
                        v-model="form.email"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Şifre" :rules="userSifreControl" v-slot="validationContext">
                    <b-form-group label="Şifre">
                      <b-input-group class="border-right">
                        <b-form-input
                          class="rounded-0"
                          ref="sifre"
                          v-model="form.sifre"
                          :type="passwordFieldType"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input>

                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Telefon" label-for="telefon">
                    <b-form-input class="rounded-0" id="telefon" name="telefon" ref="telefon" v-model="form.telefon" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Role" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group
                      label="Role Seçiniz"
                      label-for="role_adi"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                    >
                      <v-select
                        v-model="form.role_k_no"
                        :options="roller"
                        :reduce="(role) => role.k_no"
                        label="role_adi"
                        :clearable="true"
                        class="invoice-filter-select d-block"
                        :class="{ 'is-invalid': !!validationContext.errors[0] }"
                        v-on:input="handlerRoleChange($event)"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Profil Resmi" label-for="role_adi">
                    <b-form-file
                      class="rounded-0"
                      placeholder="Bir resim seçiniz"
                      drop-placeholder="Drop file here..."
                      no-drop
                      v-model="profile"
                      accept="image/jpeg, image/png, image/jpg"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu">Kullanım Durumu</label>
                <b-form-checkbox id="statu" :checked="form.statu" v-model="form.statu" switch />
              </div>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block @click="hide">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      show: false,
      master: 'user',
      k_no: null,
      roller: [],
      profile: null,
      form: {
        _id: null,
        ad_soyad: null,
        email: null,
        sifre: null,
        telefon: null,
        role_k_no: null,
        statu: true,
      },
    };
  },
  created() {
    this.handlerRoller();
  },
  methods: {
    async handlerRoller() {
      await this.$store.dispatch('rolleListele');
      this.roller = await this.$store.getters.getRoller;
    },
    handlerRoleChange(event) {
      const role = this.roller.find((x) => x.k_no == event);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        _id: null,
        ad_soyad: null,
        email: null,
        sifre: null,
        telefon: null,
        role_k_no: null,
        statu: true,
      };
      this.show = false;
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$refs.ad_soyad.focus();
    },
    onSubmit() {
      this.show = true;
      let formData = new FormData();
      let forms = this.form;
      Object.keys(forms).map(function (key) {
        formData.append(key, forms[key]);
      });
      if (this.profile !== null) {
        formData.append('profile', this.profile, this.profile.name);
      }

      const base_url = this.form._id == null ? 'kullaniciEkle' : 'kullaniciGuncelle';
      this.$store.dispatch(base_url, formData).then((res) => {
        if (res.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Ekleme`,
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: `Ekleme başarılı.`,
            },
          });
          this.resetForm();
        }
      });
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    userSifreControl() {
      if (this.form.id != null) {
        return { required: false };
      } else {
        return { required: true, min: 4 };
      }
    },
  },
  watch: {
    updateData(val) {
      if (val != null) {
        this.form._id = val._id;
        this.form.firma_k_no = val.firma_k_no;
        this.form.ad_soyad = val.ad_soyad;
        this.form.email = val.email;
        this.form.telefon = val.telefon;
        this.form.role_k_no = val.role_k_no;
        this.form.statu = val.statu;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
